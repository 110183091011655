<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
        <v-row>
          
          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              v-model="id_customer"
              :items="customers"
              item-text="nombre"
              item-value="codigo"
              @change="getByCustomer"
              label="Escoge un Cliente"
              clearable
              rounded
              solo
              hide-details
            ></v-autocomplete>
          </v-col>

        </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="transference"
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >

    <template v-slot:[`item.action`]="{ item }">
        <v-tooltip top color="purple">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green" v-bind="attrs" v-on="on" class="mr-3" @click="edit(item)">fas fa-edit</v-icon>
          </template>
          <span>Completar Pago</span>
        </v-tooltip>
    </template>
    <template v-slot:[`item.datetime`]="{ item }">
        {{ dateFormat(item.datetime) }}
    </template>
    <template v-slot:[`item.total`]="{ item }">
        {{ numberWithCommas(item.total) }}
    </template>
    <template v-slot:top>

          <v-dialog v-model="dialogUpload" max-width="450">
            <v-card>
              <v-card-title>
                <!-- <span>Pago <b>  </b> </span> -->
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <div class="detail">
                  <p> <b>RTN:</b> {{ item.rtn }} </p>
                  <p> <b>Productor:</b> {{ item.nombre }} </p>
                  <p> <b>#Factura:</b> {{ item.bill }} </p>
                </div>
                <div class="footer">
                  <p> <b>Total:</b> {{ numberWithCommas((parseFloat(item.total)).toFixed(2)) }} </p>
                </div>
              </v-card-text>

              <v-card-actions>

                <v-row no-gutters style="flex-wrap: nowrap;">
                  <v-btn
                    class="flex-grow-1 flex-shrink-0"
                    large
                    rounded
                    outlined
                    color="gray"
                    @click="close"
                  >Cancelar</v-btn>
                  <v-btn
                    class="flex-grow-1 flex-shrink-0 white--text"
                    :loading="loading1"
                    :disabled="loading1" 
                    large
                    rounded
                    color="success"
                    @click="update"
                    >Completar
                    </v-btn>
                </v-row>

              </v-card-actions>
            </v-card>
          </v-dialog>
    </template>

    <template slot="body.append">
      <tr class="gray--text">
        <!-- <th class="title"></th> -->
        
        <th colspan="6" class="title">
        <v-row class="pt-2">
          <v-col cols="12" sm="12" md="6">
            Pendiente a Pagar: {{ numberWithCommas((parseFloat(sumField('total'))).toFixed(2)) }}
          </v-col>
        </v-row>
        </th>
        
      </tr>
    </template>
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';

export default {
  name: "completePays",
  data: () => ({
    samplings: [],
    currentItems: [],
    customers: [],
    id_customer: null,
    dialogUpload: false,
    search: "",
    valid: false,
    loading: false,
    loading1: false,
    titulo: "Pagos Pendientes",
    headers: [
    { text: "Fecha", align: "left", value: "datetime"},
      { text: "RTN", align: "left", value: "rtn" },
      { text: "Productor", align: "left", value: "nombre" },
      { text: "Factura", value: "bill" },
      { text: "Total Pagado", value: "total" },
      { text: "Acciones", align: "center", value: "action", sortable: false }
    ],

    item: {},
  }),

  computed: {
    ...mapState(["db","headers_db"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getSamplings(){
      await axios.get(this.db + 'complete_pays',
        {
          headers: this.headers_db
        }).then(response => {
          this.currentItems = this.samplings = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getSamplings(); })
        });
    },

    async getCustomers(){
      await axios.get(this.db + 'providers',
        {
          headers: this.headers_db
        }).then(response => {
          this.customers = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
        });
    },

    updateStatus(id){
      axios.post(this.db + 'pay/completed', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        if (response.data.message == "Éxito al actualizar") {
          this.$store.dispatch("setSnackbar", {
            text: "Pago completado con Éxito",
          });

          this.samplings = this.samplings.filter(doc => {
            return doc.id != id
          })
        }
        else {
          this.$store.dispatch("setSnackbar", {
            text: "No se ha podido completar el pago",
            color: "error"
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.updateStatus(id); })
      });

    },
    
    // FIN Metodos base de datos

    // Metodos Generales
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a');
    },

    close() {
      this.dialogUpload = false;
    },

    sumField(key) {
      return this.currentItems.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    getByCustomer() {

      if (this.id_customer) {
        this.currentItems = this.samplings.filter(doc => {
          return doc.id_productor == this.id_customer;
        });
      }
      else {
        this.currentItems = this.samplings;
      }
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    edit(item) {
      this.item = item;
      this.dialogUpload = true;
    },

    update() {
      console.log(this.item.id);
      // return;
      this.updateStatus(this.item.id);
      this.close();
    },
  },
  mounted() {
    this.loading = true;
    this.getCustomers();
    this.getSamplings();
  },
};
</script>

<style>
.detail {
  font-size: large;
  color:black;
}
.footer {
  padding-top: 10px;
  font-size: xx-large;
  color:black;
  display: block;
  text-align: center;
}
</style>